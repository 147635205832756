<template>
  <div>
    <v-container fluid>

      <Lightbox ref="lightbox"/>

      <v-row>
        <v-col cols="12">
          <v-card class="shadow rounded-lg">

            <Tabs/>

            <v-divider/>

            <div class="pa-4">

              <v-row>
                <v-col v-if="roles('admin')" cols="12" lg="3">
                  <v-autocomplete v-model="agencyId"
                                  :items="agencies"
                                  :loading="agencySupLoading"
                                  clearable
                                  dense
                                  hide-details
                                  item-text="name"
                                  item-value="id"
                                  outlined
                                  placeholder="Agence"
                                  prepend-inner-icon="mdi-filter-outline"
                                  single-line/>
                </v-col>
                <v-col cols="12" lg="3">
                  <v-autocomplete v-model="supervisorId"
                                  :items="filtredSupervisors"
                                  :loading="agencySupLoading"
                                  clearable
                                  dense
                                  hide-details
                                  item-text="name"
                                  item-value="id"
                                  outlined
                                  placeholder="Superviseurs"
                                  prepend-inner-icon="mdi-filter-outline"
                                  single-line/>
                </v-col>
              </v-row>

              <RewardCardStat :is_loading="statLoading"
                              ref="rewardCardStat"
                              @get-statistics="getStatistics($event)"
                              :stat="stat"/>
            </div>

            <v-divider/>

            <v-row align="center" class="pa-0 ma-0 py-1 mx-1">
              <v-col cols="12" lg="3">
                <v-text-field v-model.trim="keyword"
                              class="rounded-input"
                              clearable
                              dense
                              hide-details
                              outlined
                              placeholder="Recherche..."
                              prepend-inner-icon="mdi-magnify"
                              single-line/>
              </v-col>

              <v-col class="text-end" cols="12" lg="9">

                <v-btn v-if="Object.keys(filter).length"
                       color="red"
                       text @click="[filter = {},getRewards()]">
                  <v-icon left>mdi-filter-off</v-icon>
                  Filtre
                </v-btn>

                <v-btn color="primary"
                       text
                       @click="$refs.filterDatabaseDialog.open()">
                  <v-icon left>mdi-filter</v-icon>
                  Filtre
                </v-btn>

                <v-btn color="primary" text @click="$func.export('gifty-agency/export-excel-all-rewards')">
                  <v-icon left>mdi-microsoft-excel</v-icon>
                  Exporter
                </v-btn>

                <v-btn color="primary" icon @click="[getRewards(),getStatistics(),$refs.rewardCardStat.refresh()]">
                  <v-icon>mdi-table-refresh</v-icon>
                </v-btn>
              </v-col>
            </v-row>

            <v-divider/>

            <v-card-text>

              <v-skeleton-loader v-if="isLoading" type="table"/>

              <div v-else>
                <div v-if="salepoints.length">
                  <v-simple-table class="table-border rounded-lg">
                    <template v-slot:default>
                      <thead>
                      <tr>
                        <th v-if="roles('admin')">Agence</th>
                        <th>Superviseur</th>
                        <th>Nom</th>
                        <th>Code</th>
                        <th>Magasin</th>
                        <th>N°Téléphone</th>
                        <th>Type</th>
                        <th class="text-center">Récompense</th>
                        <th class="text-center">T.ID</th>
                        <th>Date</th>

                      </tr>
                      </thead>
                      <tbody>
                      <tr v-for="(item,index) in salepoints" :key="index">

                        <td v-if="roles('admin')">
                          {{ item.supervisor.agency.name }}
                        </td>

                        <td>
                          {{ item.supervisor.name }}
                        </td>

                        <td>
                          <div class="d-flex text-no-wrap align-center">

                            <v-avatar v-if="item.salepoint.image" size="38">
                              <v-img :src="$baseUrl + item.salepoint.image"
                                     class="zoom-pointer"
                                     @click="$refs.lightbox.open(item.salepoint.image)"></v-img>
                            </v-avatar>

                            <v-avatar v-else
                                      :class="$func.randomColor()"
                                      class="font-weight-medium"
                                      size="38">
                              {{ $func.avatarName(item.salepoint.name) }}
                            </v-avatar>

                            <span class="ml-2">{{ item.salepoint.name }}</span>
                          </div>
                        </td>
                        <td>
                          <span class="font-weight-medium orange--text">{{ item.salepoint.code }}</span>
                        </td>
                        <td>{{ item.salepoint.store_name }}</td>
                        <td>
                          {{ item.salepoint.phone }}
                        </td>
                        <td>{{ item.salepoint.type }}</td>

                        <td class="text-center">
                          <v-chip class="bg-success-subtle font-weight-medium" small text-color="success">
                            {{ item.reward_points | toCurrency }} DZD
                          </v-chip>
                        </td>
                        <td class="font-weight-medium orange--text">
                          {{ item.gifty_transaction_id }}
                        </td>
                        <td>
                          {{ item.created_at }}
                        </td>
                      </tr>
                      </tbody>
                    </template>
                  </v-simple-table>

                  <v-card-actions v-if="total > 10">

                    <div v-if="!$vuetify.breakpoint.mobile"
                         class="d-flex align-center">
                      <v-select v-model="per_page"
                                :items="[10,20,50,100,200,300,400,500]"
                                :style="{width: '120px'}"
                                dense
                                hide-details
                                label="Ligne par page"
                                outlined></v-select>
                      &nbsp;
                      {{ salepoints.length }} sur {{ total }}
                    </div>

                    <v-spacer/>
                    <v-pagination v-if="total>10"
                                  v-model="pagination.current"
                                  :length="pagination.total"

                                  total-visible="6"
                                  @input="onPageChange"
                    ></v-pagination>
                  </v-card-actions>

                </div>
                <NoResult v-else/>
              </div>

            </v-card-text>
          </v-card>
        </v-col>
      </v-row>

    </v-container>

    <FilterDatabaseDialog ref="filterDatabaseDialog"
                          :has-dates="true"
                          @search="[pagination.current = 1,filter = $event,getRewards()]"/>

  </div>
</template>

<script>
import FilterDatabaseDialog from "@/views/database/components/FilterDatabaseDialog.vue";
import {HTTP} from "@/http-common";
import Tabs from "@/views/gifty/components/Tabs.vue";
import RewardCardStat from "@/views/supervisor-view/gifty/components/RewardCardStat.vue";
import axios from "axios";

export default {
    components: {RewardCardStat, Tabs, FilterDatabaseDialog},
    data() {
        return {
            overlay: false,
            salepoints: [],

            statLoading: false,
            isLoading: false,

            keyword: null,
            total: 0,
            per_page: 10,
            pagination: {
                current: 1,
                total: 0
            },
            filter: {},

            /**
             * Statistics
             */
            stat: {},

            agencySupLoading: false,
            agencies: [],
            supervisors: [],
            agencyId: null,
            supervisorId: null,
        }
    },
    methods: {
        getRewards() {
            this.isLoading = true
            HTTP.get('/gifty-agency/all-rewards?page=' + this.pagination.current, {
                params: {
                    keyword: this.keyword,
                    per_page: this.per_page,
                    filter: this.filter,
                    agencyId: this.agencyId,
                    supervisorId: this.supervisorId,
                }
            }).then((res) => {
                this.isLoading = false
                this.salepoints = res.data.data.data

                this.pagination.current = res.data.data.current_page
                this.pagination.total = res.data.data.last_page
                this.total = res.data.data.total

            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
        onPageChange() {
            this.getRewards();
        },
        getStatistics(params) {
            this.statLoading = true
            HTTP.get('/gifty-agency/all-statistics', {
                params: {
                    agencyId: this.agencyId,
                    supervisorId: this.supervisorId,
                    month :params ? params.month : null,
                    year :params ? params.year : null
                }
            }).then((res) => {
                this.statLoading = false
                this.stat = res.data.data
            }).catch(err => {
                this.statLoading = false
                console.log(err)
            })
        },
        getAgenciesAndSupervisors() {
            this.agencySupLoading = true
            axios.all([
                HTTP.get('/gifty-agency/agencies'),
                HTTP.get('/gifty-agency/supervisors')
            ]).then(axios.spread((res1, res2) => {
                this.agencies = res1.data.data
                this.supervisors = res2.data.data
                this.agencySupLoading = false
            })).catch(err => {
                console.log(err)
                this.agencySupLoading = false
            })
        }
    },
    computed: {
        filtredSupervisors() {
            if (this.agencyId) {
                return this.supervisors.filter(el => el.parent_id === parseInt(this.agencyId))
            } else {
                return this.supervisors
            }
        }
    },
    created() {
        this.getRewards()
        this.getStatistics()
        this.getAgenciesAndSupervisors()
    },
    watch: {
        keyword() {
            this.getRewards()
        },
        per_page() {
            this.pagination.current = 1
            this.getRewards();
        },
        agencyId() {
            this.pagination.current = 1
            this.getRewards();
            this.getStatistics();
        },
        supervisorId() {
            this.pagination.current = 1
            this.getRewards();
            this.getStatistics();
        },
    },
}
</script>

<style scoped>

</style>