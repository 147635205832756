<template>
  <div>

    <v-row v-if="is_loading">
      <v-col v-for="item in 4" :key="item" cols="12" lg="3">
        <v-card class="rounded-lg" outlined>
          <v-card-text>
            <v-skeleton-loader type="list-item-three-line"/>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row v-else>

      <v-col cols="12" lg="3">
        <v-card class="rounded-lg" flat dark color="primary">
          <v-card-title>
            {{ stat.total.count }}
            <v-spacer/>
            <v-avatar class="bg-grad-white" size="45">
              <v-icon dark>mdi-calendar-today</v-icon>
            </v-avatar>
          </v-card-title>

          <v-card-subtitle class="font-weight-medium white--text">
            <v-icon dark size="12">mdi-circle</v-icon>
            {{ stat.total.amount | toCurrency }} DZD
          </v-card-subtitle>

          <v-card-text>
            Récompenses
            <span class="font-weight-medium">
              Total
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <v-card class="rounded-lg" outlined>
          <v-card-title>
            {{ stat.today.count }}
            <v-spacer/>
            <v-avatar class="bg-grad-orange" size="45">
              <v-icon color="orange">mdi-calendar-week</v-icon>
            </v-avatar>
          </v-card-title>

          <v-card-subtitle class="font-weight-medium orange--text">
            <v-icon color="orange" size="12">mdi-circle</v-icon>
            {{ stat.today.amount | toCurrency }} DZD
          </v-card-subtitle>

          <v-card-text>
            Récompenses
            <span class="font-weight-medium">
                Aujourd'hui
            </span>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <v-card class="rounded-lg" outlined>
          <v-card-title>
            {{ stat.month.count }}
            <v-spacer/>
            <v-avatar class="bg-grad-blue" size="45">
              <v-icon color="blue">mdi-calendar-month</v-icon>
            </v-avatar>
          </v-card-title>

          <v-card-subtitle class="font-weight-medium blue--text">
            <v-icon color="blue" size="12">mdi-circle</v-icon>
            {{ stat.month.amount  | toCurrency }} DZD
          </v-card-subtitle>

          <v-card-text>
            <div class="d-flex justify-space-between align-center text-no-wrap">
              <div>
                Récompenses
                <span class="font-weight-medium">
                {{ setMonth(month) }}
              </span>
              </div>
              <v-menu bottom
                      right
                      transition="slide-x-transition"
              >
                <template v-slot:activator="{ on}">
                  <v-icon color="blue" v-on="on">mdi-chevron-double-down</v-icon>
                </template>
                <v-list dense nav>
                  <v-list-item v-for="(m, i) in months"
                               :key="i"
                               :class="m.value === month ? 'primary white--text' : ''"
                               class="translateY"
                               link
                               @click="[month=m.value,getStatistics()]"
                  >
                    <v-list-item-title>{{ m.name }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="3">
        <v-card class="rounded-lg" outlined>
          <v-card-title>
            {{ stat.year.count }}
            <v-spacer/>
            <v-avatar class="bg-grad-secondary" size="45">
              <v-icon color="secondary">mdi-calendar</v-icon>
            </v-avatar>
          </v-card-title>

          <v-card-subtitle class="font-weight-medium secondary--text">
            <v-icon color="secondary" size="12">mdi-circle</v-icon>
            {{ stat.year.amount | toCurrency }} DZD
          </v-card-subtitle>

          <v-card-text>
            <div class="d-flex justify-space-between align-center text-no-wrap">
              <div>
                Récompenses
                <span class="font-weight-medium">
                {{ year }}
              </span>
              </div>
              <v-menu bottom
                      right
                      transition="slide-x-transition"
              >
                <template v-slot:activator="{ on}">
                  <v-icon color="secondary" v-on="on">mdi-chevron-double-down</v-icon>
                </template>

                <v-list dense nav>
                  <v-list-item v-for="(y, i) in years"
                               :key="i"
                               :class="y === year ? 'secondary white--text' : ''"
                               class="translateY"
                               link
                               @click="[year=y,getStatistics()]">
                    <v-list-item-title>{{ y }}</v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>

            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";

export default {
    name: "CardStat",
    props: ['stat', 'is_loading'],
    data() {
        return {
            month: moment().format('MM'),
            year: moment().format('YYYY'),
            currentMonth: moment().format('MM'),
            currentYear: moment().format('YYYY')
        }
    },
    methods: {
        getStatistics() {
            let params = {
                month: this.month,
                year: this.year,
            }
            this.$emit("get-statistics", params)
        },
        setMonth(month) {
            return this.months.find(el => el.value === month).name
        },
        refresh() {
            this.month = moment().format('MM')
            this.year = moment().format('YYYY')
        },
    },
    computed: {
        months() {
            return [
                {name: 'Janvier', value: "01"},
                {name: 'Février', value: "02"},
                {name: 'Mars', value: "03"},
                {name: 'Avril', value: "04"},
                {name: 'Mai', value: "05"},
                {name: 'Juin', value: "06"},
                {name: 'Juillet', value: "07"},
                {name: 'Août', value: "08"},
                {name: 'Septembre', value: "09"},
                {name: 'Octobre', value: "10"},
                {name: 'Novombre', value: "11"},
                {name: 'Décembre', value: "12"},
            ]
        },
        years() {
            return ["2023", "2024", "2025", "2026", "2027", "2028", "2029", "2030"]
        },
    }
}
</script>

<style scoped>

</style>