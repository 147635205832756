<template>
  <div>
    <v-dialog v-model="dialog" persistent scrollable width="450">

      <v-card>

        <v-card-title>
          Filtrer par
          <v-spacer/>
          <v-icon @click="dialog = false">mdi-close</v-icon>
        </v-card-title>

        <v-divider/>

        <v-card-text class="pa-10">

          <v-combobox v-model="filter.codes"
                      chips
                      class="autocomplete mb-3"
                      clearable
                      deletable-chips
                      dense
                      hint="Saisir le code et cliquer sur en entrée pour saisir un autre"
                      label="Codes"
                      multiple
                      outlined
                      persistent-hint
                      small-chips>
          </v-combobox>


          <v-autocomplete v-if="hasGifty"
                          v-model="filter.gifty"
                          :items="giftyStatus"
                          dense
                          item-text="name"
                          item-value="value"
                          label="Gifty"
                          outlined>
          </v-autocomplete>

          <v-autocomplete v-model="filter.cities"
                          :items="filterElements.cities"
                          dense
                          label="Wilayas"
                          multiple
                          outlined>
          </v-autocomplete>

          <v-autocomplete v-model="filter.regions"
                          :items="filterElements.regions"
                          dense
                          label="Régions"
                          multiple
                          outlined>
          </v-autocomplete>


          <v-autocomplete v-model="filter.municipalites"
                          :items="filterElements.municipalities"
                          dense
                          label="Communes"
                          multiple
                          outlined>
          </v-autocomplete>

          <v-autocomplete v-model="filter.types"
                          :hide-details="!hasDates"
                          :items="filterElements.types"
                          dense
                          label="Types"
                          multiple
                          outlined>
          </v-autocomplete>

          <v-menu v-if="hasDates"
                  v-model="menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  min-width="auto"
                  offset-y
                  transition="scale-transition"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field v-model="filter.dates"
                            clearable
                            dense
                            hide-details
                            label="Dates"
                            outlined
                            prepend-inner-icon="mdi-calendar"
                            readonly
                            v-bind="attrs"
                            v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker v-model="filter.dates" range>
              <v-spacer></v-spacer>
              <v-btn color="primary"
                     text
                     @click="menu = false"
              >
                Fermer
              </v-btn>
              <v-btn color="primary"
                     text
                     @click="menu = false"
              >
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>

        </v-card-text>

        <v-divider/>

        <v-card-actions class="grey lighten-4">
          <v-spacer/>
          <v-btn color="secondary"
                 depressed
                 @click="search">
            <v-icon left>mdi-magnify</v-icon>
            Recherche
          </v-btn>
        </v-card-actions>

      </v-card>
    </v-dialog>
  </div>
</template>

<script>

import {HTTP} from "@/http-common";

export default {
    props: ['hasGifty', 'hasDates'],
    data() {
        return {
            isLoading: false,
            menu: false,
            dialog: false,
            filter: {},
            filterElements: {},
            giftyStatus: [
                {
                    name: 'Tous',
                    value: 'all',
                },
                {
                    name: 'Avec compte',
                    value: 'yes',
                },
                {
                    name: 'Sans compte',
                    value: 'no',
                },
            ]
        }
    },
    methods: {
        open() {
            this.dialog = true
            this.distinctData()
        },
        search() {
            this.dialog = false
            this.$emit('search', this.filter)
        },
        distinctData() {
            this.isLoading = true
            HTTP.get('database/distinct-data').then(res => {
                this.filterElements = res.data.data
                this.isLoading = false
            }).catch(err => {
                this.isLoading = false
                console.log(err)
            })
        },
    }
}
</script>

<style scoped>

</style>